.evover-background {
  width: 100%;
  max-width: 100%;
  background-image: url("../assets/img/background/evover-bg.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: $backgroundColor;
}

.section-title {
  margin-top: 1.6vh;
  font-size: 32px;

  @media screen and (min-width: 976px) {
    font-size: 48px;
  }
}

.sub-text {
  margin-top: 2.5vh;
  margin-bottom: 3.9vh;
  line-height: 1.5;
  font-size: 16px;

  @media screen and (min-width: 976px) {
    font-size: 18px;
    line-height: 2;
    margin-right: 4vh;
  }
}

.btn {
  margin-top: 4.09vh;
  margin-bottom: 11.55vh;
  padding: 1.8vh 0.8vh 1.64vh 0.8vh;
  background-color: $buttonColor;
  border-radius: 0.4vh;
  border: none;
  width: 21vh;
  cursor: pointer;

  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  &:hover {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 699px) {
    height: 7vh;
    width: 30vh;
  }

  @media screen and (max-height: 699px) and (orientation: landscape) {
    height: 10vh;
    width: 38vh;
    margin-bottom: 5vh;
  }
}

/* Section HERO */
.hero {
  display: flex;
  align-items: center;
  margin-top: 80px;
  width: 100%;
  max-width: 100%;
  background-image: url("../assets/img/background/evover-bg.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-color: #dddddd;
  text-align: center;
}

.hero-margin {
  margin-top: 11.55vh;

  @media screen and (max-height: 699px) and (orientation: landscape) {
    margin-top: 4vh;
  }
}

.hero-title {
  @media screen and (max-width: 699px) {
    font-size: 30px;
    letter-spacing: -1px;
    line-height: 1.5;
  }

  @media screen and (max-height: 699px) and (orientation: landscape) {
    font-size: 30px;
  }
}

.hero-subtitle {
  margin-top: 4.09vh;
  font-size: 16px;
  line-height: 1.5;

  @media screen and (min-width: 740px) {
    font-size: 18px !important;
    letter-spacing: -0.18px;
    line-height: 2;
  }
}
/* Section HERO end */

/* Section COMPANY */
.company {
  padding: 11vh 0;
  text-align: center;

  @media screen and (min-width: 700px) {
    text-align: left;
  }
}

.company-mini-text {
  font-size: 16px;
}

.company-image {
  width: 100%;

  @media screen and (min-width: 700px) {
    width: 100%;
    max-width: 400px;
  }
}
/* Section COMPANY end */

/* Section APPROACH start */
.approach {
  background-color: $backgroundColorSecondary;
  text-align: center;
  padding-top: 11.45vh;
  padding-bottom: 14.3vh;
}
.approach-title {
  margin-top: 1.6vh;
  font-size: 32px;

  @media screen and (min-width: 976px) {
    font-size: 48px;
  }
}

.approach-content {
  display: flex;
  justify-content: space-between;
}

.approach-secondary-content {
  margin-top: 6.4vh;
}

.approach-content-image {
  img {
    width: 150px;
  }

  @media screen and (max-width: 700px) {
    img {
      width: 200px;
    }
  }
}

.approach-content-title {
  font-size: 22px;
}

.approach-content-subtext {
  margin-top: 1.5vh;
  line-height: 1.5;
}
/* Section APPROACH end */

/* Section CONTACT */
.contact {
  text-align: center;
}

.contact-title {
  font-size: 24px;

  @media screen and (min-width: 976px) {
    font-size: 32px;
  }
}
/* Section CONTACT end */

/* Footer */
.footer-content {
  font-size: 18px;
  color: $textColorOnDarkBg;
  font-family: $fontRegular;
}

.footer-content-left {
  line-height: 1.5;

  @media screen and (max-width: 699px) {
    text-align: center;
    line-height: 2;
  }
}

.footer-content-right {
  text-align: right;
  line-height: 1.5;

  @media screen and (max-width: 699px) {
    text-align: center;
    line-height: 2;
  }
}

.footer-title {
  font-size: 18px;
  font-family: $fontBold;
}

.footer-list {
  .footer-link {
    .footer-link-item {
      color: $textColorOnDarkBg;
      text-decoration: none;
      font-family: $fontRegular;
      font-size: 18px;

      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;

      &:hover,
      &:focus,
      &:active {
        color: $linkColor;
      }

      &:hover {
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
      }

      @media screen and (max-width: 699px) {
        font-size: 16px;
      }
    }
  }
}

.footer-logo {
  width: 50px;

  @media screen and (max-width: 699px) {
    margin-top: 4vh;
    margin-bottom: 2vh;
  }
}

.footer-email {
  @media screen and (max-width: 699px) {
    font-family: $fontBold;
  }
}

.footer-address {
  margin-top: 1vh;
  font-style: normal;

  @media screen and (max-width: 699px) {
    font-size: 16px;
  }
}

.footer-copyright {
  border-top: 1px solid #eeeeee;
  background-color: #fff;
  margin-top: 80px;
  padding: 3.28vh 0;
  font-size: 16px;
  text-align: center;

  @media screen and (max-width: 699px) {
    border-top: none;
    margin-top: 30px;
  }
}
/* Footer end */
