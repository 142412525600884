$spacing: 24px;
$spacingDesktop: 16px;
$columns: 2;
$columnsDesktop: 8;

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 700px) {
    max-width: 976px;
  }
}

.row-nav-height {
  height: 80px;
}

.row-center {
  justify-content: center;
}

.row-space-between {
  justify-content: space-between;
}

.row-align-center {
  align-items: center;
}

.col {
  margin: 0 calc(#{$spacing} / 2);

  @media screen and (min-width: 700px) {
    margin: 0 calc(#{$spacingDesktop} / 2);
  }
}

.col-space-between {
  @media screen and (min-width: 700px) {
    justify-content: space-between;
  }
}

.col-end {
  @media screen and (min-width: 700px) {
    margin-left: auto;
  }
}

.col-content {
  flex-basis: calc((100% / #{$columns} * 2) - #{$spacing});

  @media screen and (min-width: 700px) {
    flex-basis: calc((100% / #{$columnsDesktop} * 6) - #{$spacingDesktop});
  }
}

.col-2 {
  flex-basis: calc((100% / #{$columns} * 2) - #{$spacing});

  @media screen and (min-width: 700px) {
    flex-basis: calc((100% / #{$columnsDesktop} * 2) - #{$spacingDesktop});
  }
}

.col-3 {
  flex-basis: calc((100% / #{$columns} * 2) - #{$spacing});

  @media screen and (min-width: 700px) {
    flex-basis: calc((100% / #{$columnsDesktop} * 3) - #{$spacingDesktop});
  }
}

.col-4 {
  flex-basis: calc((100% / #{$columns} * 2) - #{$spacing});

  @media screen and (min-width: 700px) {
    flex-basis: calc((100% / #{$columnsDesktop} * 4) - #{$spacingDesktop});
  }
}

.col-8 {
  @media screen and (min-width: 700px) {
    flex-basis: 100%;
  }
}
