/* Mobile First Approach */

@import "../assets/fonts/Gilroy.css";
@import "./colors.scss";

$fontRegular: Gilroy;
$fontBold: Gilroy-Bold;
$fontSemiBold: Gilroy-SemiBold;

/* Headings */
h1,
h2,
h3 {
  font-family: $fontBold;
}

h1 {
  font-size: 40px;

  @media screen and (min-width: 700px) {
    font-size: 60px;
  }
}

h2 {
  font-size: 32px;

  @media screen and (min-width: 700px) {
    font-size: 48px;
  }
}

h3 {
  font-size: 24px;

  @media screen and (min-width: 700px) {
    font-size: 32px;
  }
}

h4,
h5 {
  font-family: $fontSemiBold;
}

h4 {
  font-size: 18px;

  @media screen and (min-width: 700px) {
    font-size: 24px;
  }
}

h5 {
  font-size: 24px;

  @media screen and (min-width: 700px) {
    font-size: 16px;
  }
}

/* Body */
p {
  font-family: $fontRegular;

  &.body-1 {
    font-size: 18px;

    @media screen and (min-width: 700px) {
      font-size: 18px;
    }
  }

  &.body-2 {
    font-size: 14px;

    @media screen and (min-width: 700px) {
      font-size: 16px;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $textColorOnLightBg;
}

p,
span {
  color: $textColorOnLightBgSecondary;
}

/* Links */
a {
  font-family: $fontSemiBold;
  font-size: 16px;
}

/* Buttons */
.btn-content {
  font-size: 18px;
  font-family: $fontSemiBold;
  color: $buttonTextColor;
  text-decoration: none;
  letter-spacing: -0.4px;
}
