.btn-box {
  display: flex;
  margin-left: auto;
  padding-right: 3vh;
}

.btn-menu {
  background: none;
  border: none;
  cursor: pointer;
  visibility: visible;

  @media screen and (min-width: 700px) {
    visibility: hidden;
  }

  &:focus {
    outline: none;
  }

  span {
    display: block;
    width: 25px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: $navItemColor;

    border-radius: 3px;
    z-index: 1;

    transition: transform 200ms;
    transform-origin: 4px 0px;
    transition: transform 500ms cubic-bezier(0.77, 0.2, 0.05, 1), background 500ms cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 550ms ease;
  }

  &:first-child {
    transform-origin: 0% 0%;
  }

  &:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
}

/* From hamburger to crossmark */
nav.active ~ div button.btn-menu > .btn-menu-icon span {
  opacity: 1;
  transform: rotate(45deg) translate(-1.8px, 0px);

  /* Hides middle line */
  &:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /* Change first line direction */
  &:nth-last-child(1) {
    transform: rotate(-45deg) translate(0, -4.2px);
  }
}

nav {
  @media screen and (max-width: 699px) {
    position: fixed;
    top: 80px;
    left: 100vw;
    width: 100vw;
    height: 100vh;
    background-color: $navColor;
    transition: transform 500ms;

    & ul {
      display: flex;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center;

      & li a {
        font-size: 24px;
        line-height: 360%;
      }

      & li:first-child {
        margin-left: 0 !important;
      }
    }
  }
}

.active {
  transform: translateX(-100vw);
}

/* White line after navbar */
.line-white {
  border-top: 1px solid #eee;

  @media screen and (min-width: 700px) {
    border-top: none;
  }
}
