@import "./_config/colors";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-behavior: smooth;
}

li {
  list-style-type: none;
}

/* Navigation */
header {
  background: $navColor;
  height: 80px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

.nav-logo {
  position: relative;
  z-index: 10;
  margin-left: 3vh;
  height: 40px;
  width: 203px;

  @media screen and (min-width: 700px) {
    height: 50px;
    width: 254px;
  }
}

.nav-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nav-right {
  flex-grow: 1;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (min-width: 700px) {
      margin-left: 20%;
    }
  }
}

.nav-item {
  color: $navItemColor;
  text-decoration: none;

  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;

  &:hover,
  &:focus,
  &:active {
    color: $linkColor;
  }

  &:hover {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
  }
}

.nav-item-highlight {
  background-color: $linkColor;
  padding: 8px 20px;
  border-radius: 2px;

  &:hover,
  &:focus,
  &:active {
    color: $navItemColor;
    opacity: 0.8;
  }
}
