/* Background */
$backgroundColor: #f5f5f5;
$backgroundColorSecondary: #f5f5f5;

/* Heading  */
$headingColor: #292929;

/* Text body */
$textColorOnDarkBg: #292929;
$textColorOnDarkBgSecondary: rgba(82, 82, 82, 0.8);
$textColorOnLightBg: #292929;
$textColorOnLightBgSecondary: rgba(82, 82, 82, 0.8);
$bodyColorPrimary: #292929;
$bodyColorSecondary: rgba(82, 82, 82, 0.8);

/* Navigation, Links, Buttons */
$navColor: #ffffff;
$navItemColor: #292929;
$linkColor: #fad600;
$buttonColor: #fad600;
$buttonTextColor: #292929;
